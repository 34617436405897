<template>
  <div>
    <section class="top section">
      <div class='container has-text-centered'>
        <div class='title'>{{ $t("recommendations_component.title") }}</div>
        <div class='subtitle'>{{$t("recommendations_component.subtitle")}}</div>
      </div>
    </section>
    <section class="section results">
      <div class='container'>
        
        <!-- Nieuwe korte lijst -->
        <!-- //////// -->

        <ul v-if="!full_list">
          <recommended-name-card v-for="recommended_name in recommendations.predicted_names.slice(0, 2)"
          :key="recommended_name.name" v-bind:name_properties='recommended_name'/>
        </ul> 

        <!-- Name 3 with bottom transparency border -->
        <div v-bind:class="{ card_with_transparent_border_wrapper: !full_list }" v-if="!full_list">
          <recommended-name-card v-bind:name_properties='recommendations.predicted_names[2]' 
          class="card_with_transparent_border"/>
        </div>        

        <!-- Save list -->
        <div class="has-text-centered" v-if="!full_list" id="save_list">
          <p class="title">{{$t("recommendations_component.save_email.title") }}</p>
          <p class="margin_bottom" v-html="$t('recommendations_component.save_email.info')"></p>
          <div class="margin_bottom">
            <p class="has-text-left label_bold">{{$t("recommendations_component.save_email.email_adres")}}</p>
            <div class="field">
              <p class="control has-icons-right">
                <input class="input" type="email" placeholder="Email" v-model="email">
                <!-- <span class="icon is-small is-left">
                  <font-awesome-icon icon="envelope" />
                </span> -->
                <span class="icon is-small is-right green_icon" v-if="email_correct">
                  <font-awesome-icon icon="check" />
                </span>
              </p>
            </div>
          </div>
          <!-- <p class="has-text-left label_bold">{{$t("recommendations_component.save_email.email_adres_partner")}}</p>
          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input class="input" type="email" placeholder="Email" v-model="email_partner">
              <span class="icon is-small is-left">
                <font-awesome-icon icon="envelope" />
              </span>
              <span class="icon is-small is-right green_icon" v-if="email_partner_correct">
                <font-awesome-icon icon="check" />
              </span>
            </p>
          </div> -->
          <p class="has-text-left margin_bottom general_conditions">
            <!-- <label class="container_checkbox">{{$t("recommendations_component.save_email.general_conditions")}}
              <input type="checkbox" v-model="general_conditions_checked" id="checkbox">
              <span class="checkmark"></span>
            </label> -->
            <input type="checkbox" id="checkbox" v-model="general_conditions_checked">
            <label for="general_conditions_checked" class="label_bold" 
              v-html="$t('recommendations_component.save_email.general_conditions')"> 
            </label>
            <br>
            <span>Je email adres wordt enkel gebruikt voor het opsturen van deze lijst en 
            (na jouw bevestiging) voor de nieuwsbrief van
              <a href="https://www.goedgemerkt.nl/privacy-policy" target="_blank" class="general_conditions_2_link">Goedgemerkt.nl</a> en 
              <a href="https://www.mamabaas.be/privacy-cookies-nl?utm_source=mamabaas-tool&utm_medium=referral&utm_campaign=names-i-like" target="_blank" class="general_conditions_2_link">Mamabaas.com</a>
            </span>
          </p>
          <p class="send_email_button" v-bind:class="{disabled:disable_send_button}" v-on:click="send_email" 
          v-if="!email_send">
            <font-awesome-icon icon="paper-plane" />
            <span>{{$t("recommendations_component.save_email.send_action")}}</span>
          </p>
          <p class="has-text-centered email_send" v-if="email_send">
            {{$t("recommendations_component.save_email.email_coming")}}
          </p>
        </div>  
        
        <!-- Adversisement Mamabaas --> 
        <div class="has-text-centered name_card" v-if="!full_list">
            <div class="name_card_publicity">
              <p class="ad_title">Alles weten over je zwangerschap en baby?</p>
              <p class='product_description'>
                Via de gratis app van <b v-on:click="goto_mm" class="product_description_link">mamabaas.com</b> 
                krijg je dagelijks een update, quote of tip.
              </p>
              <p><img class='mamabaasimage' src='/mamabaas_materiaal/banner_400x259.jpeg' v-on:click="goto_mm"></p>  
              <!-- <p class='product_description'>
                <b>{{ $t("recommendations_component.ad_3.label") }}</b><br>
                <span class='new_price'>{{ $t("recommendations_component.ad_3.price_tag") }}</span>
              </p> -->
            </div>
        </div>
        
        
        
        
        <!-- Old full list -->
        <!-- /////// -->
        <ul v-if="full_list">
          <recommended-name-card v-for="recommended_name in recommendations.predicted_names.slice(0, 3)"
          :key="recommended_name.name" v-bind:name_properties='recommended_name'/>
        </ul>  

        <!-- Adversisement 1 -->
        <div class="grey_advertisement_indication_upper has-text-centered" v-if="full_list">
          <span><font-awesome-icon icon="caret-down" /></span>
          <span> {{ $t("recommendations_component.advertisement") }} </span>
          <span><font-awesome-icon icon="caret-down" /></span>
        </div>
        <div class="has-text-centered name_card advertisement" v-if="full_list">
            <div class="name_card_publicity">
              <p class="ad_title" >{{$t("recommendations_component.ad_2.title") }}</p>
              <p class='product_description' v-html="$t('recommendations_component.ad_2.p1')"></p>
              <div class="product_description_image_container" v-if="$parent.selected_data.sex=='m'">
                <img class='ad_2_pic' src='/goedgemerkt_materiaal/Jongens.jpg' v-on:click="goto_gg(1)">
                <span class="tag is-info" v-html="$t('recommendations_component.reduction')"></span>
              </div>  
              <div class="product_description_image_container" v-if="$parent.selected_data.sex=='f'" >
                <img class='ad_2_pic' src='/goedgemerkt_materiaal/Meisjes.jpg' v-on:click="goto_gg(1)">
                <span class="tag is-info" v-html="$t('recommendations_component.reduction')"></span>
              </div>  
              
              <p class='product_description'>
                <b>{{ $t("recommendations_component.ad_2.label") }}</b><br>
                <span class='new_price'>{{ $t("recommendations_component.ad_2.price_tag") }}</span>
              </p>
            </div>
        </div>	 
        <div class="grey_advertisement_indication_lower has-text-centered" v-if="full_list">
          <span><font-awesome-icon icon="caret-up" /></span>
          <span> {{ $t("recommendations_component.advertisement") }} </span>
          <span><font-awesome-icon icon="caret-up" /></span>
        </div>    
        <ul v-if="full_list">
          <recommended-name-card v-for="recommended_name in recommendations.predicted_names.slice(5, 9)"
          :key="recommended_name.name" v-bind:name_properties='recommended_name'/>
        </ul>

        <!-- Adversisement 2 -->
        <div class="grey_advertisement_indication_upper has-text-centered advertisement" v-if="full_list">
          <span><font-awesome-icon icon="caret-down" /></span>
          <span> {{ $t("recommendations_component.advertisement") }} </span>
          <span><font-awesome-icon icon="caret-down" /></span>
        </div>
        <div class="has-text-centered name_card" v-if="full_list">
            <div class="name_card_publicity">
              <p class="ad_title" >{{$t("recommendations_component.ad_1.title") }}</p>
              <p class='product_description' v-html="$t('recommendations_component.ad_1.p1')"></p>
              <div class="product_description_image_container" >
                <img class='ad_1_pic' src='/goedgemerkt_materiaal/kleine_naamstickers.png'  v-on:click="goto_gg(2)">
                <span class="tag is-info" v-html="$t('recommendations_component.reduction')"></span>
              </div>  
              <p class='product_description'>
                <b>{{ $t("recommendations_component.ad_1.label") }}</b><br>
                <span class='new_price' v-html="$t('recommendations_component.ad_1.price_tag')"></span>
              </p>
            </div>
        </div>    
        <div class="grey_advertisement_indication_lower has-text-centered" v-if="full_list">
          <span><font-awesome-icon icon="caret-up" /></span>
          <span> {{ $t("recommendations_component.advertisement") }} </span>
          <span><font-awesome-icon icon="caret-up" /></span>
        </div>   
        <!-- Names 10 to 14       -->
        <ul v-if="full_list">
          <recommended-name-card v-for="recommended_name in recommendations.predicted_names.slice(10, 14)"
          :key="recommended_name.name" v-bind:name_properties='recommended_name'/>
        </ul>
       
        <!-- Adversisement Mamabaas --> 
        <div class="has-text-centered name_card" v-if="full_list">
            <div class="name_card_publicity">
              <p class="ad_title">Alles weten over je zwangerschap en baby?</p>
              <p class='product_description'>
                Via de gratis app van <b v-on:click="goto_mm" class="product_description_link">mamabaas.com</b> 
                krijg je dagelijks een update, quote of tip.
              </p>
              <p><img class='mamabaasimage' src='/mamabaas_materiaal/banner_400x259.jpeg' v-on:click="goto_mm"></p>  
              <!-- <p class='product_description'>
                <b>{{ $t("recommendations_component.ad_3.label") }}</b><br>
                <span class='new_price'>{{ $t("recommendations_component.ad_3.price_tag") }}</span>
              </p> -->
            </div>
        </div>


        <!-- Names 14 to 18       -->
        <ul v-if="full_list">
          <recommended-name-card v-for="recommended_name in recommendations.predicted_names.slice(14, 18)"
          :key="recommended_name.name" v-bind:name_properties='recommended_name'/>
        </ul>        
        <!-- Adversisement 3 -->
        <div class="grey_advertisement_indication_upper has-text-centered advertisement" v-if="full_list">
          <span><font-awesome-icon icon="caret-down" /></span>
          <span> {{ $t("recommendations_component.advertisement") }} </span>
          <span><font-awesome-icon icon="caret-down" /></span>
        </div>        
        <div class="has-text-centered name_card" v-if="full_list">
            <div class="name_card_publicity">
              <p class="ad_title">{{$t("recommendations_component.ad_3.title") }}</p>
              <p class='product_description' v-html="$t('recommendations_component.ad_3.p1')"></p>
              <p><img class='cadeaubon' src='/goedgemerkt_materiaal/cadeaubon_rect.png' v-on:click="goto_gg(3)"></p>  
              <p class='product_description'>
                <b>{{ $t("recommendations_component.ad_3.label") }}</b><br>
                <span class='new_price'>{{ $t("recommendations_component.ad_3.price_tag") }}</span>
              </p>
            </div>
        </div>
        <div class="grey_advertisement_indication_lower has-text-centered" v-if="full_list">
          <span><font-awesome-icon icon="caret-up" /></span>
          <span> {{ $t("recommendations_component.advertisement") }} </span>
          <span><font-awesome-icon icon="caret-up" /></span>
        </div>    
        <!-- Names 18 until 25 -->
        <ul v-if="full_list">
          <recommended-name-card v-for="recommended_name in recommendations.predicted_names.slice(18, 25)"
          :key="recommended_name.name" v-bind:name_properties='recommended_name'/>
        </ul>

        <!-- Names  25 until 30 -->
        <ul v-if="full_list">
          <recommended-name-card v-for="recommended_name in recommendations.predicted_names.slice(25, 30)"
          :key="recommended_name.name" v-bind:name_properties='recommended_name'/>
        </ul>
        <!-- About Goedgemerkt  -->
        <div class="has-text-centered name_card" v-if="full_list"> 
          <div class='ad_title' v-html="$t('recommendations_component.about.title')"></div>
          <img src='/kasper_profile_picture.jpg' class='profile_picture' alt="Kasper Van Lombeek">
          <p class='about_kvl has-text-center'>
            <span v-html="$t('recommendations_component.about.about_kvl')" ></span>
            <span class="link_icon" v-on:click="goto_linkedin()">
              <font-awesome-icon icon="external-link-alt" />
            </span>
          </p>
          <img v-if="(locale=='be_nl') | (locale=='nl')" class="logo_goedgemerkt" src="/goedgemerkt_materiaal/logo_goedgemerkt.svg" v-on:click="goto_gg(4)">
          <img v-if="(locale=='be_fr') | (locale=='fr')" class="logo_goedgemerkt" src="/goedgemerkt_materiaal/logo_bienmarquer.svg" v-on:click="goto_gg(4)">
          <p v-html="$t('recommendations_component.about.about_gg')" class='has-text-center'></p>
        </div> 

        <!-- Restart button -->
        <div class="has-text-centered" id="restart_button" @click="restart_app">
          Begin opnieuw
        </div>



        <!-- Newsletter button -->
        <!-- <transition name="fade">
          <div class="container_newsletter_button" v-if="show_newsletter_button" 
           v-scroll-to="'#save_list'" v-on:click="handle_extra_names_teaser_click">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item has-text-centered">
                  <div>
                    <p><font-awesome-icon icon="envelope" class="email_icon"/></p>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item has-text-left ">
                  <div v-html="$t('recommendations_component.extra_names_teaser')">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition> -->

        <!-- Newsletter popup -->
        <!-- <newsletter-modal v-if="show_newsletter_popup"></newsletter-modal> -->
      </div>
    </section>
  </div>
</template>

<script>
import RecommendedNameCard from './RecommendedNameCard.vue';
import NewsletterModal from './NewsletterModal.vue';

import {event_bus} from '../../main' 
import axios from 'axios'
export default{  
  components:{
    'recommended-name-card': RecommendedNameCard,
    'newsletter-modal': NewsletterModal,
  },
  i18n:{
    messages:{
      be_nl:{
        recommendations_component:{
          title:'Onze suggesties:', 
          subtitle:'Op basis van je antwoorden stellen we deze namen voor:',
          save_email:{
            title:'Wil je graag nog meer namen zien?',
            //general_conditions: 'Ik accepteer de <a href="https://www.goedgemerkt.nl/terms_and_cond" target="_blank">algemene voorwaarden</a>\
            //          en het privacybeleid van <a href="https://www.goedgemerkt.nl/privacy-policy" target="_blank">Goedgemerkt.nl</a> \
            //          en <a href="https://www.mamabaas.be/mama-worden/algemeen/zwanger-een-baby-ontdek-dan-gratis-onze-verbeterde-mama-baas-app?utm_source=mamabaas-tool&utm_medium=referral&utm_campaign=names-i-like" \
            //          target="_blank">Mamabaas.be</a> ',
            general_conditions: 'Ik accepteer onderstaande voorwaarden',
            general_conditions_2: 'Je email adres wordt enkel gebruikt voor het opsturen van deze lijst en \
            (na jouw bevestiging) voor de nieuwsbrief van\
            <a href="https://www.goedgemerkt.nl/privacy-policy" target="_blank" class="general_conditions_2_link">Goedgemerkt.nl</a> en \
            <a href="https://www.mamabaas.be/mama-worden/algemeen/zwanger-een-baby-ontdek-dan-gratis-onze-verbeterde-mama-baas-app?utm_source=mamabaas-tool&utm_medium=referral&utm_campaign=names-i-like" target="_blank" class="general_conditions_2_link">Mamabaas.com</a>',
            info: 'Schrijf je in op de nieuwsbrief van <b>Goedgemerkt.nl</b> en <b>Mamabaas.com</b> en we sturen je graag de volledige \
            lijst van gepersonaliseerde suggesties.',
            send_action:'Verstuur',
            email_adres: 'E-mailadres',
            email_adres_partner: 'E-mailadres partner (optioneel)',
            email_coming:'E-mail onderweg!',
          },
          reduction: '10% kortingcode:<br>BABYNAAM-10',
          advertisement:'advertentie',
          ad_1:{
            title:'Gepersonaliseerde naam stickers?',
            p1:'Deze zijn <b>superhandig</b> om je spullen te taggen.',
            label:'Kleine naamstickers',
            price_tag:'Vanaf € 13,49',
          },
          ad_2:{
            title:'Gepersonaliseerde naamstickers?',
            p1:'Het ideale babyborrel cadeautje! In alle vormen en kleuren, op <b>www.goedgemerkt.nl</b> je vind zeker iets naar je smaak.',
            label:'Stickers (babypakket)',
            price_tag:'Vanaf € 23,95',
          },    
          ad_3:{
            title:'Gepersonaliseerde naam stickers?',
            p1:'Origineel en leuke cadeaubon voor op <b>www.goedgemerkt.nl</b>',
            label:'Cadeaubon',
            price_tag:'Vanaf € 10,00',
          },    
          about:{
            title: 'Names I Like?',
            about_nil:'Names I Like werkt op basis van \
            een recommendation algorithm zoals dat van Netflix of Spotify.',
            about_kvl: 'Names I Like is ontwikkeld door data-scientist \
            <b>Kasper Van Lombeek</b>',
            about_gg:'Names I like wordt gesteund door <b>Goedgemerkt.nl</b>, dé labelspecialist. \
            Ze maken persoonlijke labels in allerlei vormen en kleuren, het meest aanbevolen en gebruikt door de zeer goede kwaliteit.'
          },
          extra_names_teaser:'Nog 20 extra<br>namen?'                
        }
      },
      nl:{
        recommendations_component:{
          title:'Onze suggesties:', 
          subtitle:'Op basis van je antwoorden stellen we deze namen voor:',
          save_email:{
            title:'Wil je graag nog meer namen zien?',
            general_conditions: 'Ik accepteer de algemene voorwaarden',
            general_conditions_2: 'Je email adres wordt enkel gebruikt voor het opsturen van deze lijst en \
            de nieuwsbrief van Goedgemerkt.nl',
            info: 'Schrijf je in op de nieuwsbrief van <b>Goedgemerkt.nl</b> en <b>Mamabaas.be</b> en we sturen je graag de volledige \
            lijst van gepersonaliseerde suggesties.',
            send_action:'Verstuur',
            email_adres: 'Email adres',
            email_adres_partner: 'Email adres partner (optioneel)',
            email_coming:'Email onderweg!',
          },
          reduction: '10% kortingcode:<br>BABYNAAM-10',
          advertisement:'advertentie',
          ad_1:{
            title:'Gepersonaliseerde naam stickers?',
            p1:'Deze zijn <b>superhandig</b> om je spullen te taggen.',
            label:'Kleine naamstickers',
            price_tag:'Vanaf € 13,49',
          },
          ad_2:{
            title:'Gepersonaliseerde naamstickers?',
            p1:'Het ideale babyborrel cadeautje! In alle vormen en kleuren, op <b>www.goedgemerkt.nl</b> je vind zeker iets naar je smaak.',
            label:'Stickers (babypakket)',
            price_tag:'Vanaf € 23,95',
          },    
          ad_3:{
            title:'Gepersonaliseerde naam stickers?',
            p1:'Origineel en leuke cadeaubon voor op <b>www.goedgemerkt.nl</b>',
            label:'Cadeaubon',
            price_tag:'Vanaf € 10,00',
          },                    
          about:{
            title: 'Names I Like?',
            about_nil:'Names I Like werkt op basis van \
            een recommendation algorithm zoals dat van Netflix of Spotify.',
            about_kvl: 'Names I Like is ontwikkeld door data-scientist \
            <b>Kasper Van Lombeek</b>',
            about_gg:'Names I like wordt gesteund door <b>Goedgemerkt.nl</b>, dé labelspecialist. \
            Ze maken persoonlijke labels in allerlei vormen en kleuren, het meest aanbevolen en gebruikt door de zeer goede kwaliteit.'
          },
          extra_names_teaser:'Nog 20 extra<br>namen?', 
        }
      },
      be_fr:{
        recommendations_component:{
          title:'Résultats', 
          subtitle:'Sur base de vos choix, l\'algorithme vous recommande:',
          save_email:{
            title:'Vous voulez voir la liste complète?',
            general_conditions: 'J\'accepte les conditions generales',
            general_conditions_2: 'Votre adresse e-mail est uniquement utilisée pour l\'envoi de cette liste et \
            une super promotion occasionnelle de Bienmarquer.fr',
            send_action:'Envoyez',            
            info: 'Laissez votre adresse email et nous vous enverrons cette liste.',
            email_adres: 'Adresse email',
            email_adres_partner: 'Adresse email partenaire (optionelle)',
            email_coming:'Email envoyé!',
          },
          reduction: 'Code de réduction (10%):<br>NAMESILIKE-10',
          advertisement:'publicité',
          ad_1:{
            title:'Des autocollants personnalisés?',
            p1:'Ils sont <b> super pratiques </b> pour marquer vos contenus.',
            label:'Petites étiquettes autocollantes',
            price_tag:'A partir de € 13,49',
          },
          ad_2:{
            title:'Des autocollants personnalisés?',
            p1:'Sur <b> www.bienmarquer.fr</b>, vous trouverez certainement quelque chose à votre goût.',
            label:'Pack Découverte',
            price_tag:'A partir de € 19,95',
          },    
          ad_3:{
            title:'Des autocollants personnalisés?',
            p1:'Bon cadeau original et sympa de <b> www.bienmarquer.fr </b>',
            label:'Certificat cadeau',
            price_tag:'A partir de € 10,00',
          },   
          about:{
            title: 'Names I Like?',
            about_nil:'Names I Like fonctionne sur la base de \
            un algorithme de recommandation tel que Netflix ou Spotify',
            about_kvl: 'Names I Like a été développé par data-scientist \
            <b>Kasper Van Lombeek</b>',
            about_gg: 'Names I like est soutenu par <b>Bienmarquer.fr</b>, le spécialiste des labels. \
            Ils fabriquent des étiquettes personnelles de toutes formes et couleurs, \
            les plus recommandées et utilisées grâce à leur très bonne qualité.'            
          },
          extra_names_teaser:'Encore 20<br>de noms en plus?', 
        }
      },
      fr:{
        recommendations_component:{
          title:'Résultats', 
          subtitle:'Sur base de vos choix, l\'algorithme vous recommande:',
          save_email:{
            title:'Vous voulez voir la liste complète?',
            general_conditions: 'J\'accepte les conditions generales',
            general_conditions_2: 'Votre adresse e-mail est uniquement utilisée pour l\'envoi de cette liste et \
            une super promotion occasionnelle de Bienmarquer.fr',
            send_action:'Envoyez',            
            info: 'Laissez votre adresse email et nous vous enverrons cette liste.',
            email_adres: 'Adresse email',
            email_adres_partner: 'Adresse email partenaire (optionelle)',
            email_coming:'Email envoyé!',
          },
          reduction: 'Code de réduction (10%):<br>NAMESILIKE-10',
          advertisement:'publicité',
          ad_1:{
            title:'Des autocollants personnalisés?',
            p1:'Ils sont <b> super pratiques </b> pour marquer vos contenus.',
            label:'Petites étiquettes autocollantes',
            price_tag:'A partir de € 13,49',
          },
          ad_2:{
            title:'Des autocollants personnalisés?',
            p1:'Sur <b> www.bienmarquer.fr</b>, vous trouverez certainement quelque chose à votre goût.',
            label:'Pack Découverte',
            price_tag:'A partir de € 19,95',
          },    
          ad_3:{
            title:'Des autocollants personnalisés?',
            p1:'Bon cadeau original et sympa de <b> www.bienmarquer.fr </b>',
            label:'Certificat cadeau',
            price_tag:'A partir de € 10,00',
          },   
          about:{
            title: 'Names I Like?',
            about_nil:'Names I Like fonctionne sur la base de \
            un algorithme de recommandation tel que Netflix ou Spotify',
            about_kvl: 'Names I Like a été développé par data-scientist \
            <b>Kasper Van Lombeek</b>',
            about_gg: 'Names I like est soutenu par <b>Bienmarquer.fr</b>, le spécialiste des labels. \
            Ils fabriquent des étiquettes personnelles de toutes formes et couleurs, \
            les plus recommandées et utilisées grâce à leur très bonne qualité.'            
          },
          extra_names_teaser:'Encore 20<br>de noms en plus?', 
        }
      },  
      de:{
        recommendations_component:{
          title:'Unsere Vorschläge', 
          subtitle:'Aufgrund Ihrer Antworten schlagen wir folgende Namen vor:',
          save_email:{
            title:'Speicher diese Liste',
            general_conditions: 'Ich akzeptiere die Allgemeinen Geschäftsbedingungen',
            general_conditions_2: 'Ihre E-Mail-Adresse wird nur zum Versenden dieser Liste verwendet und \
            eine gelegentliche Superaktion von Gutmarkiert.de',
            info: 'Hinterlassen Sie Ihre E-Mail-Adresse und wir senden Ihnen diese Liste zu.',
            send_action:'Senden',
            email_adres: 'E-Mail-Adresse',
            email_adres_partner: 'E-Mail-Adresse Ihres Partners (nicht erfolderlich)',
            email_coming:'E-Mail unterwegs!',
          },
          reduction: 'Rabattcode (10%):<br>NAMESILIKE-10',
          advertisement:'werbepause',          
          ad_1:{
            title:'Personalisierte Namensaufkleber?',
            p1:'Diese sind <b>super praktisch</b> um Ihre Sachen zu markieren.',
            label:'Kleine Namensaufkleber',
            price_tag:'Vanaf â 13,49€',
          },
          ad_2:{
            title:'Personalisierte Namensaufkleber?',
            p1:'In allen Formen und Farben, auf <b>www.gutmarkiert.de</b> Sie werden auf jeden Fall etwas für Ihren Geschmack finden.',
            label:'Aufkleber (Babypaket)',
            price_tag:'Vanaf â 23,95€',
          },    
          ad_3:{
            title:'Personalisierte Namensaufkleber?',
            p1:'Origineller und schöner Geschenkgutschein für <b> www.gutmarkiert.de </ b>',
            label:'Geschenkgutschein',
            price_tag:'Vanaf â 10,00€',
          },     
          about:{
            title: 'Names I Like?',
            about_nil:'Names I Like works with a recommendation algorithm such as the one from Spotify or Youtube.',
            about_kvl: 'Names I Like is developped by data-scientist \
            <b>Kasper Van Lombeek</b>',
            about_gg: 'Names I like is sponsered by <b>www.gutmarkiert.de</b>. \
            Gutmarkiert produces personalized stickers of all colors and shapes,\
            they are highly recommended thanks to their outstanding quality.'            
          },                         
          gg_pub: 'Diese Website wird Ihnen angeboten durch:',        
        }      
      }
    },
  },
  props:['recommendations'],
  data(){
    return{
      locale:'',
      min_year:'',
      max_year:'',
      email:'',
      email_partner:'',
      general_conditions_checked:false,
      email_send:false,
      show_newsletter_button:false,
      show_newsletter_popup:false,
      full_list:false,
    }
  }, 
  computed:{
    email_correct: function(){
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());      
    },
    email_partner_correct:function(){
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email_partner).toLowerCase());  
    },
    disable_send_button: function(){
      //contition_1 = 
      var condition_1 = this.email_correct
      var condition_2 = (this.email_partner == '') || this.email_partner_correct
      var condition_3 = this.general_conditions_checked
      return !(condition_1 && condition_2 && condition_3)
    }
  },
  methods:{
    restart_app(){
      console.log('restart')
      this.$router.push({ name: 'homepage' })
    },
    handle_extra_names_teaser_click(){
      this.$parent.$parent.register_click('extra_names_teaser_clicked','');
      //this.show_newsletter_popup = true;
    },
    send_email(){
      if(!this.disable_send_button){
        // Send email via backend
        axios
          .get(process.env.VUE_APP_API_URL + 'send_confirmation_newsletter_email', {
            params:{
              'user_id': this.$parent.$parent.session_parameters.user_id,
              'session_id': this.$parent.$parent.session_parameters.session_id,            
              'model_id': this.$parent.$parent.session_parameters.model_id,            
              'locale': this.$i18n.locale,
              'email': this.email,
              'email_partner': this.email_partner,
              'selected_sex':this.$parent.selected_data.sex,
              'current_url': window.location.href
            }
          })
        // Register click etc
        this.$parent.$parent.register_click('email_send', {'email': this.email, 'email_partner':this.email_partner})
        this.email_send=true;
        this.$ga.event({
          eventAction:'3D_send_email', 
          eventCategory:'building_model',
        })
        fbq('trackCustom', '3D_send_email');
      }
    },
    goto_linkedin(){
      this.$parent.$parent.register_click('user_going_to_gg','')
      window.open('https://www.linkedin.com/in/kasper-van-lombeek-679aa927/', '_blank')
    },
    goto_gg(ad_number){      
      this.$ga.event({
        eventAction:'3C_click_to_gg', 
        eventCategory:'building_model',
      })
      fbq('trackCustom', '3C_click_to_gg');
      this.$parent.$parent.register_click('user_going_to_gg', 
        {'winning_name': this.name, 
        'from': 'pub_card_between_recommendations', 
        'ad_number': ad_number})
      if(ad_number==3) window.open('https://www.goedgemerkt.nl/various-detail.asp?productid=26', '_blank')
      else if(ad_number==2) window.open('https://www.goedgemerkt.nl/combivoordeel/babypakket', '_blank')
      else if(ad_number==1) window.open('https://www.goedgemerkt.nl/naamstickers/trendy-naamstickers', '_blank')
      else window.open('https://www.goedgemerkt.nl', '_blank');
    },
    goto_mm(){
      this.$ga.event({
        eventAction:'3C_click_to_mm', 
        eventCategory:'building_model',
      })
      fbq('trackCustom', '3C_click_to_mm');
      this.$parent.$parent.register_click('user_going_to_mm', 
        {'winning_name': this.name, 
        'from': 'pub_card_between_recommendations'})
      window.open('https://www.mamabaas.be/mama-worden/algemeen/zwanger-een-baby-ontdek-dan-gratis-onze-verbeterde-mama-baas-app?utm_source=mamabaas-tool&utm_medium=referral&utm_campaign=names-i-like', '_blank')
    }
  },
  created(){
    if(this.$route.name == 'view-recommendations'){
      // Actions you only do if a user comes back for the full list
      this.full_list = true;
      this.$parent.$parent.register_click('return_for_full_list', '')
      this.$ga.event({
          eventAction:'4D_return_for_full_list', 
          eventCategory:'building_model',
      })
      // Hide language button
      event_bus.$emit('force_language_buttons_to_hide')
    }else{
      // Actions you do normally
      // Backend returns if the user already confirmed the newsletter. If so, always show the full list    
      //console.log('User confirmed newsletter?' + this.recommendations.user_already_confirmed_newsletter)
      this.full_list = this.recommendations.user_already_confirmed_newsletter
    }
    //window.addEventListener('scroll', this.register_scroll);
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));
    // Variables for the time series graph
    this.min_year = this.recommendations.min_year
    this.max_year = this.recommendations.max_year
    // Make sure the homebutton is shown
    event_bus.$emit('force_home_button_to_show')
    // Measure if at bottom of page
    window.onscroll = function(ev) {
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) {
        window.onscroll = null
        this.$parent.$parent.register_click('user_scrolled_to_bottom_recommendations', '')
      }
    }.bind(this);
    // Timer, after X seconds show newsletter popup button
    setTimeout(
      function() {
        this.show_newsletter_button = true;
        this.$parent.$parent.register_click('newsletter_button_shown', '')
      }.bind(this), 10000);
  },    
  destroyed() {
    //window.removeEventListener('scroll', this.register_scroll);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');

.container_newsletter_button{
  z-index:999;
  position: fixed;
  bottom: 1em;
  left:0px;
  background-color: $reddish-orange;
  color:white;
  padding: 0.5em;
  font-weight:bolder;
  font-size:1.1em;
  box-shadow: 0 1px 2px 0 $reddish-orange;
  border-radius: 0 0.5em 0.5em 0;
  margin-left:0px;
}
.container_newsletter_button .email_icon{
  font-size:1.6em;
  margin-left:0.5em;
}
.fade-enter-active, .fade-leave-active {
  transition: all 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  margin-left: -500px;
  opacity:0;
}
.top.section{
  padding-top:4em;
}
.results{
  padding-top:0em;
}
.name_card{
  padding:1em;
  background: white;
	box-shadow: 1px 1px 8px 0 $dark-rose;
	position: relative;
  max-width:400px;
  margin: auto;
  margin-top: 0.75em;
  //margin-bottom:5em;
}

// .name_card .title{
//   padding-top: 1em;
//   font-weight: bold;
//   font-size: 1.5em;
//   color: $reddish-orange;
//   cursor: pointer;
// }
// .name_card img{
//   width:50%;
//   max-width:300px;
// }

.name_card.advertisement{
  margin-top:0.25em;
}
.grey_advertisement_indication_upper{
  margin:auto;
  margin-top: 1em;
  background-color: lightgrey;
  max-width: 400px;
}
.grey_advertisement_indication_lower{
  margin:auto;
  margin-top: 0.25em;
  background-color: lightgrey;
  max-width: 400px;
}
.ad_title{
  margin-top:1em;
  margin-bottom:1em;
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
  color: $green-button;
}
.ad_1_pic{
  margin-top: 2em;
  max-width: 60%;
}
.ad_2_pic{
  margin-top: 2em;
  max-width: 90%;
}
.label{
  color: $dark-sky-blue
}
.cadeaubon{
  width:70%;
  max-width: 199px;
  margin-top:1em;
}

.mamabaasimage{
  width:90%;
  max-width: 300px;
  margin-top:1em;
  cursor: pointer;
}

.input:focus, input:active{
  border-color: #4a90e2;
  -webkit-box-shadow: 0 0 0 0.125em rgba(249, 97, 40, 0.25);
  box-shadow: 0 0 0 0.125em rgba(74, 144, 226, 0.25);
}
.margin_bottom{
  margin-bottom:1.3em;
}
.label_bold{
  font-weight:bolder;
}
.label_bold a {
  text-decoration: underline;
}
.general_conditions span{
  font-size:0.8em;
}
// #mydiv {
//     background: red;
//     height: 20em;
//     overflow: auto;
//     border: 0 solid transparent;
//     border-width: 10px 0;
// }
.card_with_transparent_border_wrapper{
  position:relative;
}
// .card_with_transparent_border::after{
//     content: '';
//     position: absolute;
//     left: -5em;
//     right: -5em;
//     height: 10em;
//     bottom: -1em;
//     background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to($rose));
//     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $rose);
// }
#save_list{
  
  z-index: 100;
  padding-top: 1em;
  padding-left:1em;
  padding-right:1em;
  padding-bottom:1em;
  position: relative;
  max-width: 400px;
  margin: auto;
  margin-top: 2em;
  box-shadow: 1px 1px 8px 0 $dark-rose;
  background: white;
  //background-color: $rose;
  //background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to($dark-rose));
  //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $dark-rose);  
  //box-shadow: 20px 20px 20px 20px $dark-rose;
}
#save_list input{
  background-color: rgba(0,0,0, 0.1);
  border-color:rgba(0,0,0,0.1);
  font-style: italic;
}

.general_conditions_2_link{
  font-weight: bold;
  text-decoration: underline;
  color:#211551;
}

// #save_list input[type='checkbox']{
//   height: 1em;
//   -webkit-appearance: none;
//   width: 2em;
//   height: 2em;
//   border-radius: 5px;
//   transform: translateY(0.5em);
// }

.send_email_button{
  max-width:200px;
  margin:auto;
  margin-top: 2em;
  font-size: 1.1em;
  font-weight:bolder;
  padding: 0.5em 0.75em;
  color: white;
  background: $green-button;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 $green-button;
  &:hover {
          background: darken($green-button, 20%);
      }
  .fa{
    font-size: 1.7em;
    transform: translateX(5%) translateY(20%);
    -webkit-transform: translateX(5%) translateY(20%);
  }
}
.send_email_button.disabled{
  opacity: 0.3;
}
.send_email_button span{
  margin-left:0.5em;
}
.green_icon{
  color:seagreen !important;
}
.email_send{
  margin-top: 1em;
  font-weight: bold;
  color: #4a90e2;
}
.product_description_image_container{
  position:relative;
}
.product_description_link{
  cursor: pointer;
}
.tag{
  position:absolute;
  top:2em;
  right:0px;
  font-weight:bold;
  transform: rotate(20deg);
  height:3em !important;
}
.about_kvl{
  margin-bottom:3em;
}
.profile_picture{
    border-radius: 50%;
    width:50%;
    padding: 0.5em;
    max-width: 200px;
    min-width: 150px;
}
.logo_goedgemerkt{
    min-width: 10em;
    width: 50%;
    max-width: 15em;
}
.link_icon{
  text-decoration: none;
  color: $dark-indigo !important;
  font-weight:bolder;
  margin-left:0.5em;
}

/* Customize the label (the container) */
.container_checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.container_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container_checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_checkbox .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

#restart_button{
  margin-top:1em;
  font-weight: bolder;
  text-decoration: underline;
  cursor: pointer;
}

</style>
